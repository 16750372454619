<template>
    <v-container
        class="container-juego"
    >
        <iframe
            style="position: relative; bottom:0; width: 100%; height: 100%; padding:0; margin:0;"
            src="https://irisglobalback.digitabilities.com/juego/trex/index.html"
            frameborder="0"
            scrolling="no"
            allowtransparency="true"
        />

    <!--  <iframe
    src="https://irisglobalback.digitabilities.com/juego/index.html"
    scrolling="auto"
    allowfullscreen=""
    width="100%"
    height="100%"
    frameborder="0"
/>-->
    </v-container>
</template>

<script>
import postMixin from '@/mixins/postMixin'

export default {
    name: 'JuegoDespedida',
    mixins: [postMixin],
    methods: {
        resizeIframe(obj) {
            obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px'
        }
    }
}
</script>

<style scoped>
.container-juego {
  display: grid;
  /*justify-content: center;
  justify-items: center;*/
  bottom: 0;
  left: 0;
  width: 94vw;
  max-width: 1050px;
  height: 75vh;
  padding: 0;
  margin: 0;

  /*border: 1px solid #ff375f;*/
}

@media (min-width: 1264px) {
  .container-juego {
    max-width: 100% !important;
  }
}

</style>
